/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Grommet } from "grommet"
import { grommet } from "grommet/themes"
import { css } from "styled-components"

import TopNav from "./TopNav";
import BottomNav from "./BottomNav/index";
import Header from "./Header";
import Helmet from "@simplur/gatsby-theme-full-site/src/components/Helmet";
import Footer from "./footer"
import { ConfigContext } from "@simplur/gatsby-theme-full-site/src/contexts/ConfigContext"
import flatObject from "@simplur/gatsby-theme-full-site/src/utils/flatObject"

const enhancedTheme = (baseTheme, customTheme) => {
  const flatTheme = flatObject(customTheme)
  const colors = {
    ...grommet.global.colors,
    ...flatTheme,
  }

  return {
    ...baseTheme,
    global: {
      ...baseTheme.global,
      colors,
    },
  }
}

const Layout = ({ children, location }) => {
  const config = useContext(ConfigContext)

  return (
    <div className={`${location === "home" ? "layout-homepage" : "layout"}`}>
      <Helmet />
      <TopNav />
      <Header />
      <BottomNav />
      <main className="site-main" id="main">
        <Grommet
          theme={enhancedTheme(grommet, config.theme)}
          auto
          css={css`
            scroll-behavior: smooth;
          `}
        >
          {children}
        </Grommet>
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
